import _objectSpread from "C:\\docker\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.function.name.js";
import { Consumable, Employee } from "@/js/services/baseService";
import { debounce } from 'lodash';
export function useConsumableHints() {
  var hints = reactive({
    temp: {},
    list: [],
    chooseList: [],
    visible: false,
    keyword: ''
  });
  var getHints = debounce(function (keyword) {
    Consumable.getHints({
      keyword: keyword
    }).then(function (res) {
      var chooseIds = hints.chooseList.map(function (row) {
        return row.id;
      });
      hints.list = res.data.map(function (item) {
        return _objectSpread(_objectSpread({}, item), {}, {
          checked: chooseIds.includes(item.id)
        });
      });
    });
  }, 300);
  watch(function () {
    return hints.keyword;
  }, function (val) {
    getHints(val);
  }, {
    immediate: true
  });

  var openHints = function openHints(val) {
    var chooseHints = val.hints.map(function (item) {
      return item.id;
    });
    hints.keyword = '';
    hints.list.forEach(function (item) {
      if (chooseHints.includes(item.id)) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    hints.temp = val;
    hints.visible = true;
  };

  var chooseHint = function chooseHint(item) {
    var index = hints.chooseList.findIndex(function (row) {
      return row.id === item.id;
    });

    if (index > -1) {
      item.checked = false;
      hints.chooseList.splice(index, 1);
    } else {
      item.checked = true;
      hints.chooseList.push(item);
    }
  };

  var addHints = function addHints() {
    hints.temp['hints'] = hints.chooseList.filter(function (item) {
      return item.checked;
    });
  };

  return {
    hints: hints,
    openHints: openHints,
    chooseHint: chooseHint,
    addHints: addHints
  };
}
export function useConsumableEmployees() {
  var employees = reactive({
    temp: {},
    list: [],
    visible: false,
    keyword: ''
  });

  function getEmployees(day) {
    Employee.employeeInBranch({
      target_at: day,
      limit_group_code: ['BR_ANG', 'BR_ARO', 'BR_CON', 'BR_NUS', 'BR_MAG', 'BR_MGA']
    }).then(function (data) {
      employees.list = data.filter(function (row) {
        return row.limit_group_code !== "BR_DOC";
      }).filter(function (row) {
        return row.work_status != 2;
      }).map(function (row) {
        return {
          id: row.id,
          name: row.name,
          value: row.id,
          checked: false
        };
      });
    });
  }

  var openEmployees = function openEmployees(val) {
    var chooseEmployees = val.employees.map(function (item) {
      return item.id;
    });
    employees.list.forEach(function (item) {
      if (chooseEmployees.includes(item.id)) {
        item.checked = true;
      } else {
        item.checked = false;
      }
    });
    employees.temp = val;
    employees.visible = true;
  };

  var addEmployees = function addEmployees(item) {
    item.checked = !item.checked;
    employees.temp['employees'] = employees.list.filter(function (item) {
      return item.checked;
    });
  };

  return {
    employees: employees,
    getEmployees: getEmployees,
    openEmployees: openEmployees,
    addEmployees: addEmployees
  };
}